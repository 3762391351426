/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it
import "./src/styles/index.css"
import analytics from './src/utils/tracking'

export const onRouteUpdate = loc => {
  const { pathname, href } = loc.location

  analytics.page({
    path: pathname,
    referrer: '',
    search: '',
    title: `PokerCoaching ${pathname}`,
    url: href
  });
}
