import Analytics from 'analytics';
import segmentPlugin from '@analytics/segment';

const analytics = Analytics({
  plugins: [
    segmentPlugin({
      writeKey: 'kQyTVt9josPKphJVK4CtWNfqo0mAX43c'
    })
  ]
});

export default analytics;